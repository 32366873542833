const appendScriptToDocument = ({ src, isAsync = false, isDefer = false, id }) => {
  const isScriptAlreadyAppended = document.querySelector(`#${id}`);

  if (isScriptAlreadyAppended) return;

  const scriptElement = document.createElement('script');

  scriptElement.setAttribute('src', src);
  scriptElement.setAttribute('id', id);
  scriptElement.setAttribute('async' , isAsync);
  scriptElement.setAttribute('defer' , isDefer);
  document.head.appendChild(scriptElement);
};

const handleValidityCheck = (element) => {
  const elementValidity = element?.validity;
  const shouldClearErrors = !elementValidity || elementValidity?.valid;
  const errors = [];

  if (shouldClearErrors) return errors;

  for (const error in elementValidity) {
    if (elementValidity[error]) errors.unshift({ type: "alert", message: element.validationMessage });
  }

  return errors;
};


export {
  appendScriptToDocument,
  handleValidityCheck,
};
