const getWordWithoutAccentsAndSpaces = (word) =>
  word
    .toLowerCase()
    .trim()
    .split(' ')
    .join('')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const getMatchingWordInList = (
  word = '',
  list = [],
  getItem = item => item,
  ) =>
    list.filter(item =>
      getWordWithoutAccentsAndSpaces(word).includes(getWordWithoutAccentsAndSpaces(getItem(item)))
      || getWordWithoutAccentsAndSpaces(getItem(item)).includes(getWordWithoutAccentsAndSpaces(word))
    );


export {
  getMatchingWordInList,
};
