<template>
  <div
    @focusout="handleFocusOut"
    :class="[wrapperStyle, 'field-input__wrapper']"
    ref="wrapper">
    <div :class="[containerStyle, 'field-input__container']">
      <div :class="[inputWrapperStyle, 'field-input__input-wrapper']">
        <div
          v-if="hasLeftIcons"
          class="field-input__actions-wrapper field-input__actions-wrapper--left">
          <!-- @slot Left icons  -->
          <slot name="icons-left"></slot>
        </div>
        <!-- @slot Custom label  -->
        <slot name="label">
          <label
            v-if="label"
            :for="id"
            class="field-input__label">
            {{ label }}
          </label>
        </slot>
        <textarea
          v-if="isTextareaInput"
          v-bind="$attrs"
          v-on="{
            ...$listeners,
            input: handleInput,
            valid: checkValidity,
            invalid: checkValidity,
            change: checkValidity
          }"
          :value="value"
          :id="id"
          :maxlength="charactersLimit"
          :placeholder="placeholder"
          :class="[inputStyle, 'field-input__input']"
          wrap="hard"
          rows="1"
          cols="40"
          ref="input">
        </textarea>
        <input
          v-else
          v-bind="$attrs"
          v-on="{
            ...$listeners,
            input: handleInput,
            valid: checkValidity,
            invalid: checkValidity,
            change: checkValidity,
          }"
          :value="value"
          :id="id"
          :placeholder="placeholder"
          :class="[inputStyle, 'field-input__input']"
          :min="minNumberValue || false"
          :max="maxNumberValue || false"
          :style="inputMaxWidth"
          ref="input"/>
        <slot name="hint"></slot>
        <div
          v-if="hasRightIcons || isShowClearButton || (isSearchFieldInput && value)"
          class="field-input__actions-wrapper field-input__actions-wrapper--right">
          <!-- @slot Right icons  -->
          <Icon
            v-if="isShowClearButton || (isSearchFieldInput && value)"
            @click="clearFieldInput"
            :variant="'cross-outline'"
            :tag="'button'"
            :size="'xs'"
            isCircled>
          </Icon>
          <slot name="icons-right"></slot>
        </div>
        <div
          v-if="isTextareaInput && charactersLimit"
          class="field-input__limit">
          <slot name="limit">
            {{ $t('artist.dashboard.menu.header.editPerformance.description.signCount.countdown', { signCountdown: limit }) }}
          </slot>
        </div>
      </div>
      <slot
        v-bind="{ matchingComboBoxOptions, handleComboOptionSelect }"
        name="combo-box">
        <ul
          v-if="isComboBox && isComboBoxOpen"
          class="field-input__combo-box-list">
          <li
            v-for="(option, index) in matchingComboBoxOptions"
            :key="`combo-box-option-${index}`"
            class="field-input__combo-box-item">
            <button
              @mousedown="handleComboOptionSelect(option)"
              type="button"
              class="field-input__combo-box-item-button">
              {{ option }}
            </button>
          </li>
        </ul>
      </slot>
    </div>

    <!-- @slot Custom error  -->
    <slot name="error">
      <span
        v-if="errorsAggregate.length"
        :title="errorsAggregate[0].message"
        class="field-input__error-message">
        {{ errorsAggregate[0].message }}
      </span>
    </slot>
  </div>
</template>

<script>

import Icon                           from '../Icon/a-Icon.vue';
import { handleValidityCheck }        from '../../../utils/DOMUtils.js';
import { getMatchingWordInList }      from '../../../utils/stringUtils.js';


export default {
  name: 'a-FieldInput',
  inheritAttrs: false,
  components: { Icon },
  data: () => ({
    localErrors: [],
    isComboBoxOpen: false,
  }),
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    isLiveValidation: {
      type: Boolean,
      default: true,
    },
    isFluid: {
      type: Boolean,
      default: false,
    },
    isAutofocus: {
      type: Boolean,
      default: false,
    },
    isShowClearButton: {
      type: Boolean,
      default: false,
    },
    isGhost: {
      type: Boolean,
      default: false,
    },
    hasLeftIcons: {
      type: Boolean,
      default: false,
    },
    hasRightIcons: {
      type: Boolean,
      default: false,
    },
    isComboBox: {
      type: Boolean,
      default: false,
    },
    comboBoxOptions: {
      type: Array,
      default: () => ([]),
    },
    errors: {
      type: Array,
      default: () => [],
    },
    charactersLimit: {
      type: Number,
      default: 1000000,
    },
    maxNumberValue: {
      type: Number,
      default: 0,
    },
    minNumberValue: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.isAutofocus) this.$refs.input?.focus();
    if (this.isTextareaInput) this.setTextAreaAutoHeight();
  },
  computed: {
    errorsAggregate() {
      return [...this.errors, ...this.localErrors];
    },
    hasInfoMessage() {
      return (
        this.errorsAggregate.length &&
        ['valid', 'warning', 'alert'].includes(this.errorsAggregate[0].type)
      );
    },
    isSearchFieldInput() {
      return this.$attrs.type === 'search';
    },
    isTextareaInput() {
      return this.$attrs.type === 'textarea';
    },
    wrapperStyle() {
      return {
        'field-input__wrapper--fluid': this.isFluid,
        'field-input__wrapper--ghost': this.isGhost,
        ...(this.errorsAggregate[0]?.type
          ? { [`field-input__wrapper--${this.errorsAggregate[0]?.type}`]: true }
          : {}
        ),
      };
    },
    containerStyle() {
      return { 'field-input__container--disabled': this.$attrs.disabled || this.$attrs.readonly !== undefined, };
    },
    inputWrapperStyle() {
      return {
        'field-input__input-wrapper--filled': ![undefined, null, ''].includes(this.value),
        'field-input__input-wrapper--ghost': this.isGhost,
        'field-input__input-wrapper--textarea': this.isTextareaInput,
        'field-input__input-wrapper--no-label': !this.label,
        'field-input__input-wrapper--left-icon': this.hasLeftIcons,
        'field-input__input-wrapper--right-icon': this.hasRightIcons || this.isShowClearButton || (this.isSearchFieldInput && this.value),
        'field-input__input-wrapper--sides-icons': this.hasLeftIcons && this.hasRightIcons,
      };
    },
    inputStyle() {
      return {
        'field-input__input--textarea': this.isTextareaInput,
        'field-input__input--textarea-limit': this.isTextareaInput && this.charactersLimit,
      };
    },
    inputMaxWidth() {
      if (!this.isGhost) return {};

      const numberOfCharacters = this.value?.toString()?.length;

      return {
        'max-width': numberOfCharacters ? `${numberOfCharacters + 1}ch` : 'auto',
      };
    },
    limit() {
      return this.charactersLimit - this.value.length;
    },
    matchingComboBoxOptions() {
      return getMatchingWordInList(this.value, this.comboBoxOptions);
    },
  },
  watch: {
    value(value) {
      this.localErrors = [];

      if (this.maxNumberValue && (value.toString().startsWith('00') || (Number(value) && Number(value) > this.maxNumberValue) && (value.length >= this.maxNumberValue.toString().length))) return this.$emit('input', this.maxNumberValue);
      if (this.minNumberValue && (value.toString().startsWith('00') || (Number(value) && Number(value) < this.minNumberValue) && (value.length >= this.minNumberValue.toString().length))) return this.$emit('input', this.minNumberValue);
      if (this.isLiveValidation) this.$nextTick(this.checkValidity);

      this.isComboBoxOpen = Boolean(value);
    },
  },
  methods: {
    handleInput(event) {
      /**
       * Emits the value of the input field
       *
       * @event input
       * @type {string || number}
       */
      const inputType = this.$attrs.type;
      const value = inputType === 'number'
        ? event.target.valueAsNumber
        : event.target.value;
      const isSafeValue = ![null, undefined].includes(value) && !Number.isNaN(value);
      const isMaxLengthInput = this.$attrs.maxlength && value?.toString()?.length === this.$attrs.maxlength;

      if (this.isTextareaInput) this.setTextAreaAutoHeight();
      if (isMaxLengthInput) this.$emit('maxlength');

      this.$emit('input', isSafeValue ? value : '');
    },
    handleFocusOut(event) {
      if (!this.$refs.wrapper.contains(event.relatedTarget)) this.isComboBoxOpen = false;
    },
    handleValidityCheck,
    checkValidity() {
      const errors = this.handleValidityCheck(this.$refs.input);

      this.localErrors = errors.length ? [ ...errors, ...this.localErrors ] : [];
    },
    clearFieldInput() {
      this.$emit('input', '');
      this.$emit('clear');
    },
    setTextAreaAutoHeight() {
      this.$refs.input.style.height = 'auto';
      this.$refs.input.style.height = `${Math.max(120, this.$refs.input.scrollHeight)}px`;
    },
    handleComboOptionSelect(option) {
      this.$emit('option-selected', option);
      this.$nextTick(() => {
        this.isComboBoxOpen = false;
        this.checkValidity();
      });
    },
  },
};
</script>

<style lang="scss">

.field-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    font-family: var(--font-stack-secondary);
    color: var(--color-black);

    &--fluid {
      width: 100%;
    }

    &--ghost {
      width: auto;

      .field-input__container {
        flex-direction: row;
        flex: 0 1 auto;
        background-color: transparent;
        border: none;

        &:focus-within {
          box-shadow: none;
          border: none;
        }
      }

      .field-input__input-wrapper {
        flex-direction: row;
        flex: 0 1 auto;
        padding: 0;
        height: auto;
      }

      .field-input__input {
        text-align: center;
        max-width: 100%;
      }

      .field-input__actions-wrapper {
        padding: 0;
      }
    }

    $levels: "valid", "warning", "alert";

    @each $level in $levels {
      &--#{$level} {
        color: var(--color-#{$level});
      }
    }
  }

  &__container {
    display: flex;
    position: relative;

    &--disabled {
      .field-input__actions-wrapper {
        background-color: var(--color-grey-light);
      }

      * {
        cursor: not-allowed;
      }

      svg {
        fill: var(--color-grey-neutral);
      }

      .field-input__input,
      .field-input__actions-wrapper,
      .field-input__actions-wrapper--left,
      .field-input__actions-wrapper--right {
        border-color: var(--color-grey-semi) !important;
        background-color: var(--color-grey-light);
      }
    }
  }

  &__input-wrapper {
    position: relative;
    flex: 1;
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    min-width: 0;
    color: var(--color-black);

    &--textarea {
      height: initial;
      min-height: auto;
      margin: 0 auto;

      .field-input__input {
        font-size: var(--text-sm);
      }
    }

    &--ghost {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &--filled,
    &:focus-within {
      color: var(--color-black);

      .field-input__label {
        @extend .field-input__label;
      }

      .field-input__input {
        border: 1px solid var(--color-black);
      }
    }

    &:focus-within {
      .field-input__actions-wrapper--left {
        border-width: 2px;
      }
    }

    &--no-label {
      .field-input__input {
        flex: 1;
      }
    }

    &--left-icon {
      grid-template-columns: auto 1fr;

      .field-input__input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none !important;
      }
    }

    &--right-icon {
      grid-template-columns: 1fr auto;

      .field-input__input {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none !important;
      }
    }

    &--side-icons {
      grid-template-columns: auto 1fr auto;
    }
  }

  &__input {
    flex: 1 0 auto;
    height: var(--space-main-height);
    padding: var(--space-base);
    font-weight: var(--font-medium);
    font-size: var(--text-sm);
    line-height: var(--lineheight-xs);
    color: var(--color-black);
    background-color: transparent;
    border: 1px solid var(--color-black);
    border-radius: var(--rounded-xs);

    @include textOverflowEllipsis();

    &:read-only {
      border-color: var(--color-grey-semi);
      background-color: var(--color-grey-light);
    }

    &:focus {
      outline: none;
      border-width: 2px !important;

      + .field-input__actions-wrapper--right {
        border-width: 2px;
      }
    }

    &::placeholder {
      position: relative;
      top: -1px;
      font-size: var(--text-xxs);
      font-style: italic;
      color: var(--color-grey-neutral);
    }

    &--textarea {
      resize: none;
      white-space: initial !important;
      overflow: initial !important;
      text-align: left !important;

      &-limit {
        padding-bottom: var(--space-lg);
      }
    }
  }

  &__label {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    margin-bottom: var(--space-xs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    line-height: var(--lineheight-md);
    color: var(--color-black);

    @include textOverflowEllipsis();
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
    padding: 0 var(--space-base);
    background-color: transparent;
    line-height: 0;
    user-select: none;

    &--left {
      border-radius: var(--rounded-xs);
      border-width: 1px;
      border-right: none;
      border-color: currentColor;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--right {
      border-radius: var(--rounded-xs);
      border-width: 1px;
      border-left: none;
      border-color: currentColor;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__limit {
    position: absolute;
    right: var(--space-sm);
    bottom: var(--space-xxs);
    font-style: italic;
    font-size: var(--text-xxs);
    color: var(--color-grey-semi);
  }

  &__combo-box-list {
    z-index: 10;
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    transform: translateY(var(--space-sm));
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .025);
  }

  &__combo-box-item {
    padding: var(--space-xxs) var(--space-lg);
    font-size: var(--text-sm);
    color: var(--color-black) !important;

    &:first-child {
      padding-top: var(--space-md);
    }
    &:last-child {
      padding-bottom: var(--space-md);
    }
  }

  &__combo-box-item-button {
    width: 100%;
    text-align: left;
  }

  &__error-message {
    margin-top: var(--space-xxs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xxs);
    font-weight: 600;
    line-height: var(--lineheight-xs);
    text-align: left;

    $levels: "valid", "warning", "alert";

    @each $level in $levels {
      &--#{$level} {
        color: var(--color-#{$level});
      }
    }
  }
}
</style>
